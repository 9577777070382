<template>
  <div class="main02">
    <div class="live-container" v-if="isEnd">
      <div v-show="isShowChat">
        <div class="ctn02">
          <live-bar
            :anchormanInfo="anchormanInfo"
            :anchormanRealList="anchormanRealList"
            :closeVol="closeVol"
            :liveList="liveList"
          ></live-bar>

          <LiveChatroom
            :anchormanRealList="anchormanRealList"
            :anchormanInfo="anchormanInfo"
            :chatlist="chatlist"
            :liveList="liveList"
            :roomHotList="roomHotList"
            :sendDame="sendDame"
          ></LiveChatroom>
        </div>
      </div>

      <div v-show="!isShowChat">
        <div class="close-back" @click.stop="goBack()">
          <img src="@/assets/images/close_3.png" alt="close" />
        </div>
        
        <div class="chat_on" @click.stop="showChat()">
          <img src="../../assets/images/clear-off.svg" alt="close" />
        </div>
      </div>
    </div>
    <div class="live_end" v-else>
      <img
        :src="liveList.anchormanLiveVo.pic"
        v-if="Object.keys(liveList).length > 0"
        alt="anchoravatar"
        class="end_bj"
      />
      <div class="end_ctn">
        <p class="end_text">直播已结束</p>
        <img class="end_img" :src="anchormanInfo.logo? anchormanInfo.logo: require('../../assets/images/avatar2.png')" alt="avatar"/>
        <p class="end_text2">{{ anchormanInfo.name? anchormanInfo.name: "主播已下线" }}</p>
        <div class="end_btn" @click="goHome()">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import LiveVideo from "@/components/LiveVideo/index";
import LiveChatroom from "../../components/LiveChatRoom/index.vue";
import liveBar from "../../components/liveBar/index.vue";
import GetUtils from "../../utils/utils";
import { Toast, Dialog } from "vant";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "myRoom",
  data() {
    return {
      liveList: {}, // 直播数据
      anchormanRealList: {}, // 直播状态数据
      chatlist: {}, // 聊天室信息

      isEnd: true, // 是否结束
      roomHotList: {
        roomHot: false, // 聊天室是否满员
        roomHotTxt: "" //满员提示
      },

      isShowChat: true // 是否显示聊天
    };
  },
  props: ["sendDame", "closeVol", "anchormanInfo", "anchormanRealList33"],
  components: {
    LiveVideo,
    LiveChatroom,
    liveBar
  },
  computed: {
    // 取值
    ...mapState({
      token: state => state.home.token
    })
  },
  created() {
    this.initial();
    // 切换聊天是否显示
    this.$bus.on("show-chat", this.showChat);
  },
  mounted() {
    // console.log('第二个页面token', this.token)

    setTimeout(() => {
      this.getGiftTop3();
    }, 1000);
    // 定时器20s刷新
    setTimeout(() => {
      this.onlineCheck();
      this.refreshHot();
    }, 20000);
  },
  beforeDestroy() {
    this.$store.commit("SET_WATCH_FUN", false);
  },
  methods: {
    ...mapMutations({
      M_TOKEN: "M_TOKEN",
      SET_MEMBER_ID: "SET_MEMBER_ID",
      SET_IS_EFFECTS: "SET_IS_EFFECTS"
    }),
    ...mapActions({
      getGiftTop3Fn: "getGiftTop3Fn"
    }),

    onlineCheck() {
      this.$homeApi.postRequest({
        apiName: "onlineCheck"
      });
      setTimeout(() => {
        this.onlineCheck();
      }, 60000);
    },
    async refreshHot() {
      // 直播实时数据查询
      let res_3 = await this.$homeApi.postRequest({
        apiName: "anchormanRealData",
        memberId: 1
      });

      if (res_3) {
        if (res_3.code == "1" && res_3.data) {
          this.anchormanRealList = res_3.data;
          this.isEnd = true;
        }
        if (res_3.code == "2") {
          this.isEnd = false;
          this.$emit("getIsEnd", false);
          // 关闭后 销毁播放器
          this.$bus.emit("end-living", false);
        }
      }

      setTimeout(() => {
        this.refreshHot();
      }, 20000);
    },

    // 排行榜
    async getGiftTop3(type) {
      let res_3 = await this.getGiftTop3Fn({
        apiName: "giftTop3"
      });
      setTimeout(() => {
        this.getGiftTop3();
      }, 20000);
    },

    // getToken() {
    //   // 获取手机传值
    //   this.token = GetUtils.getUrlKey("token");
    //   const memberId = GetUtils.getUrlKey("memberId") || 556;

    //   if (this.token) {
    //     this.M_TOKEN(this.token);
    //     this.SET_MEMBER_ID(memberId);

    //   }
    // },
    getTokenIsFail(mesg) {
      //  token 失效和为空 提示重新登入
      Dialog.alert({
        message: mesg,
        theme: "round-button"
      }).then(() => {
        // on close
        if (GetUtils.getUrlKey("isRn") == 1) {
          window.postMessage(
            JSON.stringify({
              type: "onRefreshToken"
            })
          );
        }
        if (window?.AndroidClient.onRefreshToken || false) {
          window?.AndroidClient.onRefreshToken();
        }
      });
    },
    async initial() {
      // 主播信息
      if (this.anchormanInfo) {
        this.sendDataTwo();
      }
      if (this.anchormanRealList33) {
        this.anchormanRealList = this.anchormanRealList33;
      }
    },
    async sendDataTwo() {
      let [chatList2, liveList2] = await Promise.all([
        this.$homeApi.postRequest({
          apiName: "getChatroomInfoApi",
          needNearMsgList: false,
          roomType: 3,
          roomTypeId: this.anchormanInfo.liveCtId
        }),
        this.$homeApi.postRequest({
          apiName: "getAnchormanLive",
          liveCtId: this.anchormanInfo.liveCtId
        })
      ]);
      // 聊天室内容接口
      if (chatList2) {
        if (chatList2.code == "1" && chatList2.data) {
          this.chatlist = chatList2.data;
        } else if (chatList2.code == "7") {
          this.roomHotList.roomHot = true;
          this.roomHotList.roomHotTxt = chatList2.msg;
        }
      } else {
        this.sendDataTwo();
      }

      if (liveList2) {
        if (liveList2.code == "1" && liveList2.data) {
          this.liveList = liveList2.data;
        }
      } else {
        Toast.fail("网络故障，稍后再试");
        this.sendDataTwo();
      }
    },

    goHome() {
      if (GetUtils.getUrlKey("isRn") == 1) {
        window.postMessage(
          JSON.stringify({
            type: "onBackToHome"
          })
        );
      }
      if (window?.AndroidClient.onBackToHome || false) {
        window?.AndroidClient.onBackToHome();
      }
    },
    goBack() {
      this.$bus.emit('go-back');
    },
    showChat() {
      this.isShowChat = !this.isShowChat;
      this.SET_IS_EFFECTS(this.isShowChat);
      this.$bus.emit('clear-danmus', this.isShowChat);
      if(this.isShowChat) {
        Toast('内容已恢复');
      } else {
        Toast('内容已清空');
      }
    }
  }
};
</script>

<style lang="scss" scope>
.main02 {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  .live-container{
    height: 100vh;
    
    &::before{
      content: "";
      width: 100vw;
      height: 154px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(22, 22, 22, 0.35) 0%,
        rgba(0, 0, 0, 0) 80.8%
      );
    }

    &::after {
      content: "";
      width: 100vw;
      height: 371px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(22, 22, 22, 0.0001) 0%,
        rgba(22, 22, 22, 0.757403) 97.06%
      );
    }
    .close-back {
      position: absolute;
      right: 10px;
      top: 50px;
      z-index: 4;
      width: 45px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 0 10px;
      img {
        display: block;
        width: 36px;
      }
    }
    .chat_on{
      position: absolute;
      right: 20px;
      bottom: 30px;
      z-index: 4;
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
    }
  }

  .vide_ctn {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .ctn02 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }
  
}

// 苹果8手机
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
}

</style>