import { get, post } from "./axiosConfig";

const APIS = {
  // 获取用户信息
  getMemberInfo: (payload = {}) => {
    return post(payload);
  },
  getGiftList: (payload = {}) => {
    return post({ ...payload });
  },
  giftUse: (payload = {}) => {
    return post({ ...payload });
  },
  // 新的礼物接口
  getGiftListNew: (payload = {}) => {
    return post({ ...payload });
  },

  // 余额更新
  getMoneyRefresh: (payload = {}) => {
    return post({ ...payload });
  },

  // 排行榜
  getGiftTop3: (payload = {}) => {
    return post({ ...payload })
  },
  // vip座驾特效
  getCarEffectList: (payload = {}) => {
    return post({ ...payload })
  },
}

export default APIS;
