<template>
    <div class="live_bar">
      <div class="live_wrapper">
        <div>
          <div class="bar_left">
            <div class="bar_photo">
              <img :src="anchormanInfo.logo ? anchormanInfo.logo : require('../../assets/images/avatar2.png')"
                alt="avatar" />
            </div>
            <div class="bar_info">
              <span class="bar_name">{{ anchormanInfo.name }}</span>
            </div>
          </div>
          <div class="heat">
            <span class="heat_icon"></span>
            <div class="heat_num">{{ yuanGoWan(anchormanRealList.hot) }}</div>
          </div>
        </div>
        <div class="bar_right">
          <ul class="leaderboard">
            <li class="item" v-for="(item, index) in giftTop3 " :key="index" @click.stop="handAvatar(index)">
              <img :src="item.logo ? item.logo :require('../../assets/images/avatar2.png')" alt="" class="avatar01" />
              <img :src="touArr1[index].imgtou" alt="" class="avatar02" />
              <div class="mode" :class="isShow == index && isShowHide == true ? 'active' :''">
                <p class="text1">{{ item.nickName }}</p>
                <p class="text2">贡献值：{{ yuanGoWan(item.totalYd) }}</p>
              </div>
            </li>

          </ul>
          <div class="close" @click.stop="goBack()">
            <img src="@/assets/images/close_3.png" alt="" />
          </div>
        </div>
      </div>
      <div class="change_flow" v-if="liveList && liveList.anchormanLiveVo && liveList.anchormanLiveVo.secretList && liveList.anchormanLiveVo.secretList.length > 0">
        <div class="item selected" @click="changeFlowFunc">线路{{currentFlow+1}}</div>
        <div v-show="isShowFlowList">
          <div class="item" :class="{'current': currentFlow == index}" @click="selectFlowFunc(item, index)" v-for="(item, index) in liveList.anchormanLiveVo.secretList" :key="index">线路{{index+1}}</div>
        </div>
      </div>
    </div>
</template>

<script>
import GetUtils from "../../utils/utils";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";


export default {
  name: "liveBar",
  props: {
    liveList: {
      type: Object,
      default: () => {
        return "";
      },
    },
    anchormanInfo: {
      type: Object,
      default: () => {
        return "";
      },
    },
    anchormanRealList: {
      type: Object,
      default: () => {
        return "";
      },
    },
    closeVol: { type: Function }
  },
  data() {
    return {
      isShow: 6,
      isShowHide: false,
      touArr1: [
        { imgtou: require('../../assets/images/avatar_001.png')},
        { imgtou: require('../../assets/images/avatar_002.png')},
        { imgtou: require('../../assets/images/avatar_003.png')}
      ],
      isShowFlowList: false, // 是否显示线路列表
      currentFlow: 0,
    };
  },
  computed: {
    ...mapState({
      giftTop3: (state) => state.home.giftTop3,
    }),
    
  },
  created() {
    this.$bus.on('go-back', this.goBack);
  },
  methods: {
    ...mapActions({
      getIsLivePageFn: "getIsLivePageFn",
    }),
    goBack() {
      this.getIsLivePageFn(true);
      this.closeVol(false);
      // localStorage.setItem("token", '');

      // 关闭 退出全屏
      if (GetUtils.getUrlKey('isRn') == 1) {
        window.postMessage(JSON.stringify({
          type: 'onBackToHome'
        }));
      }
      if ((window?.AndroidClient && window?.AndroidClient.onBackToHome) || false) {
        window?.AndroidClient.onBackToHome();
      }
      // console.log('关闭websock',global.barrageMsg_this.websock )
      if (global.barrageMsg_this.websock.readyState == 1) {
          global.barrageMsg_this.websock.close();
        }

      gtag("event", "点击", {
        ct_click_type: "关闭按钮",
      });

    },
    handAvatar (index) {
      this.isShow = index;
      this.isShowHide = true;
      setTimeout(() => {
      this.isShowHide = false;
      }, 2000);
    },
    yuanGoWan(nub) {
      let numberA = Number( nub );

      if (numberA) {
        if (numberA > 10000) {
          numberA = (numberA / 10000).toFixed(1) + '万';
        }
      } else {
        numberA = '0';
      }
      return numberA;

    },
    // 切换线路
    changeFlowFunc() {
      this.isShowFlowList = !this.isShowFlowList;
    },
    selectFlowFunc(item, index) {
      this.currentFlow = index;
      this.isShowFlowList = false;
      this.$bus.emit('change-flow', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.live_bar {
  padding: 0 16px 0 16px;
  margin: 50px 0 7px 0;

  .live_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .bar_left {
      width: 159px;
      height: 36px;
      background: rgba(28, 28, 28, 0.4);
      border-radius: 26px;
      display: flex;
      align-items: center;
      padding: 0 0 0 2px;
      margin: 0 0 10px 0;

      .bar_photo {
        width: 32px;
        height: 32px;
        margin-right: 9px;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }

      .bar_info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          text-overflow: ellipsis;
          max-width: 96px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .heat {
        height: 27px;
        background: rgba(28, 28, 28, 0.4);
        border-radius: 26px;
        display: inline-flex;
        padding: 0 20px;
        justify-content:  center;
        align-items: center;
        box-sizing: border-box;

        .heat_icon {
          width: 12px;
          height: 14px;
          background-image: url("@/assets/images/home/heat_icon.png");
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 7px;
        }

        .heat_num {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: #ffffff;
          position: relative;
          padding-top: 2px;
        }
      }

    .bar_right {
      display: flex;
      align-items: center;
      justify-content: center;
      .close {
        width: 45px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 0 10px;
        img {
          display: block;
          width: 36px;
        }
      }
    }
    .leaderboard{
      display: flex;
      justify-content: center;
      align-items: center;
      .item{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:nth-of-type(2){
          margin: 0 6px;
        }
        .avatar01{
          display: block;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          position: relative;
          top: 3px;
          left: 1px;
          object-fit: fill;
        }
        .avatar02{
          display: block;
          width: 32px;
          height: 38px;
          position: absolute;
          left: 0;
          z-index: 2;
        }
      }
      .mode{
        width: 96px;
        height: 45px;
        background: url('@/assets/images/avatar_bj.png') no-repeat left top;
        background-size: 100% 100%;
        visibility: hidden;
        position: absolute;
        top: 125%;
        left: -100%;
        // transform: translateX(-50%);
        padding: 10px 0 0 0;
        transition: all 0.05s ease-in-out;

        .text1{
          width: 70px;
          line-height: 14px;
          margin: 0 auto 2px;
          font-size: 10px;
          color: #FFFFFF;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .text2{
          line-height: 14px;
          padding: 0 4px;
          margin: 0 auto 2px;
          font-size: 10px;
          color: #FFF27B;
          text-align: center;
        }

        &.active{
          visibility: visible;
          display: block;
        }
      }

    }
  }
  .change_flow{
    float: right;
    .item{
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50px;
      padding: 5px 10px;
      margin: 10px 0;
      &.selected{
        border: 2.5px solid #deffcc;
        color: #deffcc;
        margin: 0;
      }
      &.current{
        border: 1px solid #ffa200;
        color: #ffa200;
      }
    }
  }
}
</style>