<template>
  <div class="main">
    <div class="enter_live" :class="isLoading? '': 'noborder'" @click="enterLive()">
      <div class="ellca">
        <div class="wZrKdsSD"></div>
        <div class="d9kg8M3o"></div>
      </div>
      {{ isLoading? "正在加载": anchormanInfo.status == 1? "点击进入直播间": "直播还未开始" }}
    </div>
    <div class="home_ctn">
      <div class="home_londing">
        <div class="player_loading" v-if="isLoading">
          <img class="content" src="../../assets/images/videoloading2.gif" alt="" />
        </div>
      </div>

      <div class="video_info">
        <span class="live_icon">直播中</span>
        <p class="live_name"><span class="text">{{ anchormanInfo.name }}</span></p>
        <p class="live_desc"><span class="text">{{ anchormanInfo.title }}</span></p>
      </div>
    </div>

  </div>
</template>

<script>

import GetUtils from "../../utils/utils";
import { mapActions, mapMutations, mapState } from "vuex";
import { Toast, Dialog } from "vant";

export default {
  name: "HomeHeader",
  components: {},
  data() {
    return {
      player: null,
      anchormanInfo: {}, // 主播信息
      isBufferPlay: true, //是否加载完毕
      showLoading: true, // 是否显示loading
      mode: "",
      initialNumber: 0,
      isLoading: true, // 加载中
      timer: null,
    };
  },

  async created() {
    this.initialData();
  },
  computed: {
    // 取值
    ...mapState({
      token: (state) => state.home.token,
    }),
  },
  mounted() {
    this.mode = GetUtils.getUrlKey("mode");

    // ios调用
    window.addEventListener(
      "message",
      (event) => {
        let token = event.data;

        if ( typeof token == 'string' ) {
          let arr = token.split('-');
          if ( arr.length == 6 ) {
            // alert(token);
            this.M_TOKEN(token);
            localStorage.setItem("token", token);
            this.getMemberInfo();
            setTimeout(() =>{
              this.goToRoom();
            }, 1000);

          }
        }
      },
      false
    )
    // 给安卓调用 传参
    window.getToken = (token) => {
      let token2 = token;
      // 一直获取，直到拿到值为止
      this.timer = setInterval( () =>{
        if (token2 && token2 != '') {
          this.M_TOKEN(token2);
          localStorage.setItem("token", token2);
          this.getMemberInfo();
          clearInterval(this.timer);
          this.timer = null;

          this.goToRoom();
        }
      }, 1000);

    }

  },
  destroyed() {
    // if (this.player) {
    //   this.player.hls.destroy();
    //   this.player.destroy();
    // }
  },
  methods: {
    ...mapMutations({
      M_TOKEN: "M_TOKEN",
      SET_MEMBER_ID: "SET_MEMBER_ID",
    }),
    ...mapActions({
      getIsLivePageFn: "getIsLivePageFn",
      getMemberInfoFn: "getMemberInfoFn",
    }),

    async initialData() {
      this.isLoading = true;

      let res = await this.$homeApi.postRequest({
        apiName: "getAnchormanInfo",
        memberId: 1,
      });
      if (res && res.code == '1' && res.data) {
        this.anchormanInfo = res.data;
        this.isLoading = false;

        if (Object.keys(this.anchormanInfo).length > 0) {
        }
      }
      else if (res && res.code == '2') {
        this.isLoading = false;
        return false;
      }
      else {
        this.initialNumber++;
        if (this.initialNumber < 3) {
          this.initialData();
        } else {
          this.getErrorDialog();
        }
      }

    },
    getErrorDialog() {
      Dialog.alert({
        message: '网络异常，请稍后再试',
        theme: "round-button",
      }).then(() => {
        // on close
        // 重新刷新
        return false
      });
    },
    // h5检查是否登录 是否为会员
    async checkLogin() {
      let res = await this.getMemberInfoFn({
        apiName: "getMemberInfo",
      });
      // 接口错误重新
      if(!res) {
        this.getMemberInfo();
      }
      if(res.code == '1' && res?.data?.memberInfo) {
        let memberInfo = res.data.memberInfo
        if(memberInfo.vipInfo.level > 0 ) {
          this.goToRoom();
        }else {
          Toast.fail("会员才能进入直播间");
        }
      }else {
        Toast.fail("未登录");
      }
    },
    enterLive() {
      let url = '';
      // let url = window.location.href;
      // if (url.indexOf("?") != -1) {
      //   url = url.split("?")[0] + "myRoom";
      // } else {
      //   url = url + "myRoom";
      // }

      if (this.anchormanInfo.live) {
        let isH5 = GetUtils.getUrlKey("isH5");

        // 判断地址栏是否有isH5,有就是h5进入口
        if (isH5) {
          this.M_TOKEN(isH5);
          localStorage.setItem("token", isH5);
          this.checkLogin()
          return false;
        }

        // 模拟
        // window.getToken('f4f42ced-9e29-4301-b604-06f57963e491-22867');
        // window.postMessage('f4f42ced-9e29-4301-b604-06f57963e491-22867');

        // 进入直播之前调用APP方法
        if (GetUtils.getUrlKey("isRn") == 1) {
          // app 是否登录状态
          window.postMessage(
            JSON.stringify({
              type: "onCheckLoginStatus",
            })
          );
          window.postMessage(
            JSON.stringify({
              type: "onOpenLivePage",
              roomUrl: url,
            })
          );
        }

        // 安卓 app 是否登录状态
        if ((window.AndroidClient && window?.AndroidClient.onCheckLoginStatus) || false) {
          window?.AndroidClient.onCheckLoginStatus();
        }
        if ((window.AndroidClient && window?.AndroidClient.onOpenLivePage) || false) {
            window?.AndroidClient.onOpenLivePage(url);
        }

      } else {
        Toast.fail("直播还未开始，请稍后");
      }

    },
    goToRoom() {
      // 获取手机传值
      const memberId = GetUtils.getUrlKey("memberId") || 556;
      if (this.token) {
          this.getIsLivePageFn(false);
          this.SET_MEMBER_ID(memberId);
          localStorage.setItem("memberId", memberId);

          if (GetUtils.getUrlKey("isRn") == 1) {
            // 跳到全屏直播
            window.postMessage(
              JSON.stringify({
                type: "onFullScreen",
              })
            );
          }

        // 唤起全屏直播
        if ((window.AndroidClient && window?.AndroidClient.onFullScreen) || false) {
          window?.AndroidClient.onFullScreen();
        }
      } else {
        Toast.fail("请重新点击进入");
      }
    },
     // 获取用户信息
     async getMemberInfo() {
      let res = await this.getMemberInfoFn({
        apiName: "getMemberInfo",
      });
      // 接口错误重新
      if(!res) {
        this.getMemberInfo();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  position: relative;

  .btn_volume {
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 4;
  }

  .home_bj {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;

    .img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(10px);
    }

    .img5 {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .vide_ctn {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

  }

  .video_wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .xgplayer-skin-default {
      background: transparent;

      video {
        position: relative;
        left: -60px;
      }
    }

    ::v-deep .xgplayer-enter {
      background: transparent !important;
      display: none !important;
    }

    ::v-deep .xgplayer-start {
      display: none !important;

    }

    .video_pre_loading {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 8;
    }

    .video_bj {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // filter: blur(10px);
    }

    .bottomLoading {
      position: absolute;
      bottom: 160px;
      z-index: 9;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .bottomLoading div {
      background-color: #dfb996;
      height: 1.2px;
      animation: loading 1.2s infinite;
      animation-timing-function: ease;
      animation-direction: normal;
      -webkit-animation: loading 1.2s infinite;
      -webkit-animation-timing-function: ease;
      -webkit-animation-direction: normal;
    }

    @keyframes loading {
      0% {
        width: 40%;
        opacity: 1;
      }

      100% {
        width: 100%;
        opacity: 0;
      }
    }

    @-webkit-keyframes loading {
      0% {
        width: 40%;
        opacity: 1;
      }

      90% {
        width: 100%;
        opacity: 0;
      }

      100% {
        width: 40%;
        opacity: 0;
      }
    }
  }


  .main {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    .enter_live {
      width: 164px;
      height: 38px;
      border-radius: 31px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      transition: all 1ms;

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      &.noborder {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      &:active {
        background: #e6e6e6;
      }
      .ellca{
        height: 24px;
        position: relative;
        width: 24px;
      }
      .wZrKdsSD {
          background-size: 24px;
          font-size: 0;
          height: 24px;
          left: 0;
          position: absolute;
          top: 0;
          width: 24px;

          animation: RZtvbkZS 1s steps(94,start) forwards;
          background-image: url('../../assets/images/play_effect.cf9.png');
      }
    .d9kg8M3o{
      background-size: 24px;
        font-size: 0;
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;

        animation: IQSm9z3E 1s steps(95,start) infinite;
        background-image: url('../../assets/images/playing_effect.f3.png');
        opacity: 0;
    }

    @keyframes RZtvbkZS {
          96% {
              background-position-y: -2256px;
              opacity: 1
          }
          100% {
              background-position-y: -2256px;
              opacity: 0
          }
      }

      @keyframes IQSm9z3E {
          0% {
              opacity: 1
          }
          100% {
              background-position-y: -2280px;
              opacity: 1
          }
      }

    }
  }

  .home_ctn {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 89px;


    .video_centent {

      ::v-deep .xgplayer-controls {
        background-image: none !important;
        bottom: 20px;
      }

      // ::v-deep .xgplayer-live {
      //   display: none !important;
      // }
      ::v-deep .xgplayer-fullscreen {
        display: none !important;
      }

      ::v-deep .xgplayer-play {
        margin-left: 15px;
      }

      ::v-deep .xgplayer-volume {
        margin-right: 10px !important;
      }

      ::v-deep .xgplayer-start {
        background: none !important;
      }


    }

  }

  .video_info {
    height: 90px;
    margin: 0;
    padding: 0 0 0 17px;
    box-sizing: border-box;

    .live_icon {
      height: 22px;
      background: #ff5858;
      border-radius: 3px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 19px 0;
      padding: 5px;
    }

    .live_name {
      font-weight: bolder;
      font-size: 18px;
      color: #fff;
      margin-bottom: 10px;
      .text{
        border-radius: 4px;
        padding: 2px 6px;
        background: rgba(0, 0, 0, 0.30);
      }
    }

    .live_desc {
      font-size: 14px;
      color: #fff;
      .text{
        border-radius: 4px;
        padding: 2px 6px;
        background: rgba(0, 0, 0, 0.30);
      }
    }
  }
}

.black {
  background: #000;

  .enter_live {
    background: #2e2e2e !important;
    color: #c7c7c7 !important;
    border: none !important;
  }

  .live_name {
    color: #fff !important;
  }

  .live_desc {
    color: #dedede !important;
  }
}

.home_londing{
  display: block;
  height: 100px;
}
.player_loading {
  width: 100%;
  display: none;
  justify-content: center;

  .content {
    display: block;
    max-width: 15%;
    border: none;
    position: relative;
    top: -20px;
  }
}

@keyframes loading {
  0% {
    width: 40%;
    opacity: 1;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

@-webkit-keyframes loading {
  0% {
    width: 40%;
    opacity: 1;
  }

  90% {
    width: 100%;
    opacity: 0;
  }

  100% {
    width: 40%;
    opacity: 0;
  }
}

// 8
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
  .home .home_video {
    margin-top: 1.8rem;
  }


}
</style>