
export default {
  getUrlKey(key) {
    return decodeURIComponent((new RegExp('[?|&]' + key + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
  },
  // getUrlKey() {
  //   return new Proxy(new URLSearchParams(window.location.search), {
  //     get: (searchParams, prop) => searchParams.get(prop),
  //   });
  // }


  getAppcategory(text) {
    let hash = window.location.hash;
    if (hash != '') {
      let arr = hash.split('&');
      let para = '';
      arr.map((el, index) => {
        if (el.match(text)) {
          para = arr[index].split('=')[1];
        }
      })
      return para;
    }
  },
  getQueryString(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substring(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]); // 解密
    }
    return null;
  },
  scriptInsert(url, sucess, failed) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = () => {
        if (sucess) sucess();
        resolve(true);
      };
      script.onerror = (err) => {
        if (failed) failed();
        reject(err);
      };
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  },
  isiOS() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  // 用于处理上下拉动问题，指定区域可以滚动
  touchScroll(id) {
    var el = document.getElementById(id);
    var scrollStartPos = 0;

    el.addEventListener(
      "touchstart",
      function (event) {
        scrollStartPos = this.scrollTop + event.touches[0].pageY;

        event.stopPropagation;
        // event.preventDefault();
      },
      false
    );

    el.addEventListener(
      "touchmove",
      function (event) {
        this.scrollTop = scrollStartPos - event.touches[0].pageY;
        event.stopPropagation;
        // event.preventDefault();
      },
      false
    );
  }
}
