<template>
  <div>
    <div
      style="height: 70vh; width: 100vw; position: absolute; top: 0; z-index: 3"
      @click="toggleVideo()"
    ></div>
    <video
      class="one-player"
      id="player-container-id"
      preload="auto"
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
// 回播播放
import TCPlayer from "tcplayer.js";
import "tcplayer.js/dist/tcplayer.min.css";
export default {
  data() {
    return {
      playerTx: undefined,
    };
  },
  mounted() {
    this.$bus.on("play-video", this.playVideo);
    this.$bus.on("toggle-video", this.toggleVideo);
  },
  methods: {
    initPlayer() {
      this.playerTx = TCPlayer("player-container-id", {
        autoplay: true,
        controls: true,
        reportable: false,
      });
      document.querySelector('.tcp-right-click-popup-menu').style.display = 'none';
      // 已播放后，离开页面暂停
      window.onblur = ()=> {
        this.playerTx.pause();
        this.$bus.emit("toggle-list", this.playerTx.paused());
      };
    },
    playVideo(url) {
      if (!this.playerTx && url) {
        this.initPlayer();
      }
      if (url) {
        this.playerTx.src(url);
      }
      if (this.playerTx) {
        this.playerTx.play();
      }
    },
    // h5端点视频不能切换播放状态,需要借助额外dom
    toggleVideo() {
      if (this.playerTx) {
        if (this.playerTx.paused()) {
          this.playerTx.play();
        } else {
          this.playerTx.pause();
        }

        this.$bus.emit("toggle-list", this.playerTx.paused());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.one-player {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 2;
  top: 0;
}
</style>