<template>
  <div class="gift_data">

    <van-popup v-model="giftShow" @click-overlay="handlClose()" @opened="bindScrollEvent" position="bottom" :style="{ height: 'auto' }">
      <div class="grftbody">
        <div class="hredtitle">
          <van-icon name="arrow-down" class="close" color="#5B5B6A" @click="handlClose()" />
          <p class="text">赠送礼物</p>
        </div>
        <div class="grft">
          <ul class="grft_ur" id="grft_ur">
            <li v-for="(item, index) in listGift" :key="index" :class="grftActive.index == index ? 'active' : ''"
              @click.stop="handGrft(item, index)">
              <img class="gr" :src="item.pic" alt="" />
              <div class="titel">{{ item.name }}</div>
              <p class="text">{{ item.price }}金币</p>
            </li>
          </ul>
        </div>
        <div class="liner"></div>
        <div class="grft_footer">
          <div class="Balance">
            <p class="cash">
              余额：<span>{{
                moneyRefresh > 0 ? moneyRefresh : vipInfo.ableUseYd
              }}</span>
            </p>
            <div class="btn_recharge" @click="handRecharge()">充值</div>
          </div>
          <div class="refresh" @click="handRefresh()">
            <img src="../../assets/images/refresh.png" alt="refresh" class="refresh_icon" :class="{ animation: msk }" />
          </div>
          <div class="amout">
            <div class="left">
              数量：{{ selectGiftNum }}
              <span class="icon" @click="handShowNub()"></span>
              <!-- 弹窗 -->
              <ul class="number" v-if="nubShow">
                <li v-for="(item, index) in number" :class="numberActive.index === index ? 'active' : ''"
                  @click="handSeleNub(item, index)" :key="index">
                  {{ item }}
                </li>
                <span class="icon3"></span>
              </ul>
            </div>
            <div class="send" @click.stop="giftUse()">赠送</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import APIS from "../../http/apis";
import { Toast, Dialog } from "vant";
import GetUtils from "../../utils/utils";


export default {
  data() {
    return {
      vipInfo: {},
      memberInfo: {},
      // --------礼物----------
      listGift: [],
      selectGiftNum: 1,
      giftId: null,
      ydUseNum: null, // 总价
      showPopover: false,
      isGiftListOpened: false, // 是否已经开过一次礼物列表，用来处理滚动绑定

      giftShow: false,
      grftActive: { index: 0, item: { price: 0 } },
      arr: [
        {
          giftUrl: require("../../assets/images/gift/gift01.png"),
          name: "亲吻",
          money: 10,
        },
        {
          giftUrl: require("../../assets/images/gift/gift02.png"),
          name: "棒棒糖",
          money: 20,
        },
        {
          giftUrl: require("../../assets/images/gift/gift03.png"),
          name: "城堡",
          money: 88,
        },
        {
          giftUrl: require("../../assets/images/gift/gift04.png"),
          name: "仙女棒",
          money: 20,
        },
        {
          giftUrl: require("../../assets/images/gift/gift05.png"),
          name: "星星",
          money: 10,
        },
        {
          giftUrl: require("../../assets/images/gift/gift06.png"),
          name: "跑车",
          money: 188,
        },
        {
          giftUrl: require("../../assets/images/gift/gift07.png"),
          name: "玫瑰花束",
          money: 68,
        },
        {
          giftUrl: require("../../assets/images/gift/gift08.png"),
          name: "水晶鞋",
          money: 88,
        },
      ],
      number: [520, 188, 66, 10, 1],
      numberActive: { index: 4 },
      nubShow: false,
      msk: false,
      msk22: false,
      timer: null,
    };
  },
  props: ["giftShowDialog", "roomId"],
  computed: {
    ...mapGetters(["M_token", "M_chatData"]),
    // 取值
    ...mapState({
      userInfoData: (state) => state.home.userInfoData,
      moneyRefresh: (state) => state.home.moneyRefresh,
      isEffects: (state) => state.home.isEffects,
    }),
  },
  watch: {
    giftShowDialog(newVal) {
      if (newVal) {
        this.giftShow = newVal;
        this.getMemberInfo();
      }
    },
  },
  mounted() {
    this.getGiftList();
    // this.$nextTick(() => {
      // GetUtils.touchScroll('grft_ur');
    // })
  },
  methods: {
    ...mapMutations({
      SET_LIST_GIFT: "SET_LIST_GIFT",
      SET_GIFTS_EFFECTS: "SET_GIFTS_EFFECTS",
    }),
    ...mapActions({
      getMoneyRefreshFn: "getMoneyRefreshFn",
      getMemberInfoFn: "getMemberInfoFn",
      getGiftTop3Fn: "getGiftTop3Fn",
    }),
    bindScrollEvent() {
      if(!this.isGiftListOpened) {
        GetUtils.touchScroll('grft_ur');
        this.isGiftListOpened = true;
      }
    },
    handlClose() {
      this.giftShow = false;
      this.$emit("handGiftColse", false);
    },
    handGrft(item, index) {
      console.log(index)
      // 选择礼物
      if(!this.giftShow){
        return false;
      }
      this.grftActive.index = index;
      this.grftActive.item = item;
      // 默认为1
      this.selectGiftNum = 1;
      this.giftId = item.id;

      gtag("event", "点击", {
        ct_click_type: '选择礼物',
        ct_click_name: item.name,
      });
    },
    handShowNub() {
      this.nubShow = true;
    },
    // 选择数量
    handSeleNub(item, index) {
      this.selectGiftNum = item;
      this.numberActive.index = index;
      this.nubShow = false;
    },
    // 充值
    handRecharge() {
      this.handlClose();
      gtag("event", "点击", {
        ct_click_type: '充值'
      });
      //  唤起充值
      if (GetUtils.getUrlKey('isRn') == 1) {
        window.postMessage(JSON.stringify({
          type: 'onRecharge'
        }));
      }
      if (window?.AndroidClient.onRecharge || false) {
        window?.AndroidClient.onRecharge();
      }
    },
    // 获取用户信息
    async getMemberInfo() {
      let res = await this.getMemberInfoFn({
        apiName: "getMemberInfo",
      });
      if ( res && res.code == "1") {
        this.getMemberInfoData();
      }
    },
    // 排行榜
    async getGiftTop3() {
    let res = await this.getGiftTop3Fn({
      apiName: "giftTop3",
    });
  },

    // --------------------礼物赠送---------------------------
    giftUse() {
      this.getYDUseNum();
      // console.log("this.ydUseNum", this.ydUseNum, this.giftId,);

      this.handlClose();
      gtag("event", "点击", {
        ct_click_type: '赠送'
      });

      if (this.jinyan) {
        Toast.fail(this.jinyanTxt);
      } else if (this.roomHot) {
        Toast.fail(this.roomHotTxt);
      } else {
        if (this.ydUseNum > this.vipInfo.ableUseYd) {
          Toast.fail("赠送失败，余额不足");
        } else {
          if (!this.msk22) {
            this.msk22 = true;
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.sendData();
            this.timer = setTimeout(() => {
              this.msk22 = false;
            }, 2000);
          }

        }
      }
    },
    sendData() {
      // this.vipInfo.ableUseYd = this.vipInfo.ableUseYd - this.ydUseNum;
      APIS.giftUse({
        apiName: "giftUse",
        giftId: this.giftId,
        useNumber: this.selectGiftNum,
        roomId: this.roomId,
        totalYd: this.ydUseNum,
      }).then((response) => {
        // console.log("赠送礼物global", response);
        if (response && response.code == 1) {
          let message = {
            ct: this.giftId,
            ctType: 3,
            from: this.memberInfo.memberId,
            nickName: this.memberInfo.nickName,
            fromPhoto: this.memberInfo.logo,
            roomId: this.roomId,
            giftNum: this.selectGiftNum,
            giftId: this.giftId,
            vipLevel: this.vipInfo.level
          };

          global.barrageMsg_this.websocketsend(JSON.stringify(message));
          this.getMemberInfo();
          this.getGiftTop3();

          // Toast.success(response.msg);
          this.SET_GIFTS_EFFECTS(response.data.giftId);
        } else {
          Toast.fail(response ? response.msg : '网络故障，赠送失败');
        }
      });

    },
    showGiftData(item) { },
    getYDUseNum(item) {
      this.ydUseNum = this.selectGiftNum * this.grftActive.item.price;
    },
    //----------------礼物--------------
    async getGiftList() {
      let res = await APIS.getGiftListNew({
        apiName: "getGiftListNew",
      });
      if (res.code == "1" && res.data) {
        // console.log('000000003礼物', res)
        this.listGift = res.data.listGift;
        this.SET_LIST_GIFT(res.data.listGift);
        this.grftActive.item = this.listGift[0];
        this.giftId = this.listGift[0].id;
      }
    },
    // 余额更新
    async getMoneyRefresh() {
      let res = await this.getMoneyRefreshFn({
        apiName: "moneyRefresh",
      });
      if (res.code == "1") {
        Toast("余额更新成功！");
      } else {
        Toast("余额更新失败！");
      }
    },
    handRefresh() {
      if (!this.msk) {
        this.msk = true;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.msk = false;
          this.getMoneyRefresh();
        }, 3000);

        gtag("event", "点击", {
          ct_click_type: '刷新'
        });
      }
    },
    //----------------跳转任务中心--------------
    doTask() {
      if (this.M_token) {
        let Logistics = this.$router.resolve({
          path: "/user?id=4",
        });
        window.open(Logistics.href, "_blank");
      } else {
        this.login();
      }
    },
    // 登录 ----------start-------------lcy
    login() {
      // this.$emit("postLoginStatus", true);
      // global.vueLoginSelf.dialogVisible = true;
      // this.showPopover = true;
    },

    getMemberInfoData() {
      // console.log("获取用户", this.userInfoData);
      if (this.userInfoData) {
        this.memberInfo = this.userInfoData;
        this.vipInfo = this.userInfoData.vipInfo;
      }
    },
    //--------礼物图片------------
    getGiftImg(n) {
      n = n.toString();
      switch (n) {
        case "1":
          return require("../../assets/images/gift/gift01.png");
          break;
        case "2":
          return require("../../assets/images/gift/gift02.png");
          break;
        default:
          break;
      }
    },
  },
  filters: {
    giftType(n) {
      n = n.toString();
      switch (n) {
        case "1":
          return "鲜花";
          break;
        case "2":
          return "666";
          break;
        case "3":
          return "进球";
          break;
        case "4":
          return "红包";
          break;
        case "5":
          return "啤酒";
          break;
        case "6":
          return "收米";
          break;
        default:
          break;
      }
    },
    levelTxt(v) {
      switch (v) {
        case 12:
          return "副司";
          break;
        case 13:
          return "总司";
          break;
        case 14:
          return "荣司";
          break;
        default:
          break;
      }
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var s = date.getSeconds();
      return M + D + h + m;
    },
  },
};
</script>
<style lang="scss">
.gift_data {
  background: #fff;

  .yd_data {
    font-size: 12px;
    display: inline-block;

    p:first-of-type {
      margin-bottom: 5px;

      img {
        vertical-align: sub;
      }
    }

    p:last-of-type {
      text-align: center;
    }

    span,
    button {
      font-size: 12px;
      // height: 20px;
      cursor: pointer;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid #22bd82;
      color: #22bd82;
      padding: 3px 5px;

      &.isDisabled {
        border-color: #cccccc;
        color: #cccccc;
      }
    }
  }

  span.v_m {
    display: inline-block;
  }

  .nologin_gift_show {
    display: inline-block;

    img {
      width: 50px;
      border-radius: 7px;
      border: 1px solid #e5e5e5;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.gift_data_layer {
  &.el-popover {
    min-width: 100px !important;

    .el-button {
      border: none;
    }
  }

  .gift_num {
    margin-bottom: 20px;
    padding: 0 20px;

    .el-radio__input {
      display: none;
    }

    .el-radio.is-bordered.is-checked {
      border: 1px solid #22bd82;
      // margin-right: 0;
    }

    .el-radio__input.is-checked+.el-radio__label {
      color: #22bd82;
    }

    .el-radio.is-bordered+.el-radio.is-bordered {
      // margin-right: 10px!important;
      margin-left: 0px !important;
    }

    // .el-radio--small.is-bordered{
    //   // margin-right: 10px;
    // }
    .el-radio {
      margin-right: 8px;
    }

    .el-radio__label {
      padding-left: 5px;
    }
  }

  .giftBtn {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tipsHead {
    padding: 15px 20px;
  }

  .tipsFooter {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }
}
</style>
