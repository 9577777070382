import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import apiAddress from './apiAddress';
axios.defaults.timeout = 30 * 1000;

const retry = 3;
const domain = apiAddress;
let UUID = '';
if (localStorage.getItem('udid') == null) {
  UUID = uuidv4();
  localStorage.setItem('udid', UUID);
} else {
  UUID = localStorage.getItem('udid').toString();
}
axios.interceptors.request.use(config => {
  let BaseDate = {
    timestamp: new Date().getTime(),
    platform: 3,
    channel: 1,
    vt: '3.0',
    uniqueNo: UUID,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
  }
  config.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  config.data = { ...config.data, ...BaseDate }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
  const { data, status } = response;

  if (status != 200)
    if (data.code == 401) {

    } else if (data.code == 999) {
      // token无效

    }
    else if (data.code == 1000) {

    }

  return response.data;

}, error => {
  console.log(error)

  // if(error.response.data) {
  //   return Promise.reject({type: "error", msg: error.response.data})
  // }
  let retryCount = 0;
  // 请求> 设置好的请求次数，不再发送请求， 返回错误信息
  if(retryCount >= retry){
    if (error.message == 'Network Error') {
      return Promise.reject({type: "warning", msg: "网络故障，稍后再试"});
    }
    else if(error.message == 'timeout of 30000ms exceeded') {
      return Promise.reject({type: "warning", msg: "请求超时，稍后再试"});
    }
  }

  if (error.message == 'Network Error') {
    // retryCount += 1;
    // tautology(error);
  }
  if(error.message == 'timeout of 30000ms exceeded') {
    // retryCount += 1;
    // tautology(error);
  }

  
});

export function get(url, params, header = {}, prefix = true) {
  return new Promise((resolve, reject) => {
    axios.get(prefix ? `${domain}${url}` : `${url}`, {
      params: params
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function post(data) {
  return new Promise((resolve, reject) => {
    axios.post(`${domain}`, data).then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}

// 重新请求
function tautology(err){
  let back = new Promise(function(resolve) {
    console.log('接口失败重新请求', err.config.url);
    resolve();
  })
  return back.then(function () {
    return axios(err.config);
  })
}
