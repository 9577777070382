<template>
  <div id="app">
    <span class="loader" v-if="!isPageReady"></span>
    <Home/>
  </div>
</template>


<script>
import NoSleep from 'nosleep.js';
import Home from './views/HomeView';
export default {
  name: "app",
  components: { Home },
  data() {
    return{
      isPageReady: false,
    }
  },
  created() {
    // 用于处理页面很慢时候的loading
    this.$bus.on('page-ready', this.pageReady);
  },
  mounted() {
    var noSleep = new NoSleep();
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);
  },
  methods: {
    pageReady(bool) {
      this.isPageReady = bool;
      console.log(this.isPageReady)
    },
  }
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "NotoSans", "PingFang SC", "Helvetica Neue", "Microsoft YaHei",
    Arial, Helvetica, sans-serif !important;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

