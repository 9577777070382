// let apiaddress = 'http://18.166.88.113:8003';
let apiaddress = 'https://uatapi01.6bdf52a.com';
switch(process.env.VUE_APP_MODE) {
  case 'uat':
    // apiaddress = 'http://18.166.88.113:8003';
    apiaddress = 'https://uatapi01.6bdf52a.com';
    break;
  case 'production':
    apiaddress = 'https://api.68f4deb.com';
    break;
}

export default apiaddress  + '/league/data';
