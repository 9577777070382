<template>
  <div class="live-video">
    <div class="live-video-play">
      <div
        class="video_wrapper"
        id="videoHight"
        :style="{ height: cliHeight + 'px', minHeight: cliHeight + 'px' }"
        v-if="
          isShowVideo && anchormanRealList.live && anchormanRealList.status == 1
        "
      >
        <video class="vplayerCtn" id="player-container-id" preload="auto" playsinline webkit-playsinline ref="videoRef"
          :style="{ minHeight: cliHeight + 'px', minWidth: '100vw' }" v-if="!isVideoTag">
        </video>
        <div
          class="vplayerCtn"
          id="player-container-id"
          preload="auto"
          playsinline
          webkit-playsinline
          ref="videoRef"
          :style="{ minHeight: cliHeight + 'px', minWidth: '100vw' }"
          v-if="isVideoTag"
        ></div>
        <div class="danmu" v-show="isEffects">
          <vue-danmaku
            v-model="danmus"
            use-slot
            :speeds="70"
            :channels="4"
            :randomChannel="true"
            ref="refName"
          >
            <template slot="dm" slot-scope="{ index, danmu }">
              <div
                v-html="danmu.text"
                class="danmakuMsg"
                :class="[danmu.color, danmu.fontClass]"
                :key="index"
              ></div>
            </template>
          </vue-danmaku>
        </div>

        <div class="video_pre_loading" v-if="showLoading">
          <img
            :src="liveList.anchormanLiveVo.pic"
            v-if="Object.keys(liveList).length > 0"
            alt=""
            class="video_bj"
          />
          <!-- <div class="loading_Img">
              <div class="img1"></div>
            </div> -->
        </div>
      </div>
      <div class="live_window" v-else>
        <img
          :src="liveList.anchormanLiveVo.pic"
          v-if="Object.keys(liveList).length > 0"
          alt=""
          class="video_bj"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import AgoraRTC from "agora-rtc-sdk-ng";
import GetUtils from "../../utils/utils";
import TCPlayer from "tcplayer.js";
import "tcplayer.js/dist/tcplayer.min.css";

import vueDanmaku from "vue-danmaku";
import emojiData from "../emotion/emoji.json";

export default {
  props: {
    liveList: {
      type: Object,
      default: () => {
        return "";
      },
    },
    anchormanRealList: {
      type: Object,
      default: () => {
        return "";
      },
    },
    isVoice: { type: Boolean },
    dameMessg: { type: "" },
  },
  components: { vueDanmaku },
  data() {
    return {
      player: null,
      damuArrComments: [],
      damuId: 0, //弹幕ID
      isBufferPlay: true, //是否加载完毕
      showLoading: false, // 是否显示loading
      cliHeight: 0,
      livePlayer: null,
      AgoraAudio: null,
      AgoraRTCObj: null,
      barrageIsShow: true, //是否展示弹幕
      danmus: [],
      colorArr: [
        "color_1",
        "color_2",
        "color_3",
        "color_4",
        "color_5",
        "color_6",
        "color_7",
        "color_8",
        "color_9",
        "color_10",
        "color_11",
        "color_12",
        "color_13",
        "color_14",
        "color_15",
        "color_16",
      ],
    };
  },
  watch: {
    "anchormanRealList.live": function () {
      console.log("检查， 刷新000");
      // if (!this.watchFun) {
      //   this.initVideoFun();
      //   this.$store.commit("SET_WATCH_FUN", true);
      // }
    },
    // 声音
    isVoice(newVal, oldVal) {
      // flv 加密 未加密 情况使用加密播放器
      if(this.liveList.anchormanLiveVo.isSecret == 1) {
        if (this.isVoice) {
          this.livePlayer.cancelMute();
        } else {
          this.livePlayer.mute();
        }
      } else {
        // 有m3u8字段 并且是.m3u8后缀就用hls播放器
        if(this.liveList.anchormanLiveVo.m3u8 && this.liveList.anchormanLiveVo.m3u8.indexOf('.m3u8') != -1) {
          if (this.isVoice) {
            this.livePlayer.muted(false)
            this.livePlayer.volume(100);
          } else {
            this.livePlayer.muted(true)
            this.livePlayer.volume(0);
          }
        } else {
          switch (this.liveList.anchormanLiveVo.company) {
            // 腾讯
            case "tx":
              if (this.isVoice) {
                this.livePlayer.muted(false)
                this.livePlayer.volume(100);
              } else {
                this.livePlayer.muted(true)
                this.livePlayer.volume(0);
              }
              break;
            // 网宿
            case "wangsu":
              if (this.isVoice) {
                this.livePlayer.muted = false;
                this.livePlayer.volume = 1;
              } else {
                this.livePlayer.muted = true;
                this.livePlayer.volume = 0;
              }
              break;
            // 声网
            case "shengwang":
              if(this.AgoraAudio) {
                if(this.isVoice) {
                  this.AgoraAudio.play();
                } else {
                  this.AgoraAudio.stop();
                }
              }
              break;
            default:
              if (this.isVoice) {
                this.livePlayer.muted(false)
                this.livePlayer.volume(100);
              } else {
                this.livePlayer.muted(true)
                this.livePlayer.volume(0);
              }
              break;
          }
        }
      }
    },
    // isLivePage(newVal, oldVal) {
    //   // 第一个预览页关闭，第二个页面开启
    //   if (newVal) {
    //     this.$refs.refName.hide();
    //     this.livePlayer.remove();
    //   } else {
    //     this.$refs.refName.show();
    //     this.AgoraPlayerInit();
    //   }
    // },
    // 弹幕
    dameMessg(newValue, oldValue) {
      if (newValue) {
        this.damuToList(newValue);
      }
    },
  },
  computed: {
    ...mapState({
      userInfoData: (state) => state.home.userInfoData,
      watchFun: (state) => state.home.watchFun, // 是否调用过一次初始化视频
      isEffects: (state) => state.home.isEffects,
      isLivePage: (state) => state.home.isLivePage,
    }),
    videoId() {
      //直播室ID
      return this.liveList.anchormanLiveVo.liveCtId;
    },
    isShowVideo() {
      //是否渲染模块
      return (
        Object.keys(this.liveList).length > 0 &&
        Object.keys(this.anchormanRealList).length > 0
      );
    },
    // 播放器位置
    isVideoTag() {
      // 供应商shengwang需要用 div ,其他是用video
      // 加密流需要用div
      return this.liveList.anchormanLiveVo.company == 'shengwang' || this.liveList.anchormanLiveVo.isSecret == 1
    }
  },
  mounted() {
    this.$bus.on("clear-danmus", this.clearDanmus);
    this.$bus.on("change-flow", this.changePlayerFlow);
    this.$bus.on("end-living", this.destoryLivePlayer);
    // if (this.watchFun) {
      this.initVideoFun();
    // }
    // html5页面android软键盘顶起影响布局, 设置固定高度
    this.cliHeight = document.body.clientHeight;
    // document.getElementById('videoHight').style.height(this.cliHeight);
    window.addEventListener("resize", () => {
      this.cliHeight = document.body.clientHeight;
    });
  },

  // destroyed() {
  //   if (this.livePlayer) {
  //     this.AgoraRTCObj.client.leave();
  //     this.livePlayer.remove();
  //     this.AgoraAudio.stop();
  //   }
  // },
  beforeDestroy() {
    this.destoryLivePlayer()
  },
  methods: {
    clearDanmus(status) {
      if (status) {
        this.$refs.refName.play();
      } else {
        this.$refs.refName.stop();
        this.danmus = [];
      }
    },
    randomColor() {
      const color = [
        "#80F5E0",
        "#83E2F9",
        "#FF83F3",
        "#FBC2EB",
        "#FF9589",
        "#FFDD74",
        "#D4FC79",
        "#61F576",
        "#98FFBE",
        "#009EFD",
        "#6F3CFF",
        "#00FF19",
        "#0F61FF",
        "#FF549C",
        "#E7627D",
      ];
      let ans = "";
      let index = ~~(Math.random() * color.length);
      ans = color[index];

      return ans;
    },
    randomSpeed(num) {
      let speed = 0;
      let fontSize = "";
      if (num % 2 == 0) {
        speed = 5000;
        fontSize = "size24";
      } else {
        speed = 12000;
        fontSize = "size30";
      }
      return { speed, fontSize };
    },
    emotionImgSrc(res) {
      let word = res.replace(/\[|]/g, ""); //正则加'g'全文查找匹配
      const list = emojiData.emojiList;
      let index = list.indexOf(word);
      return (
        "<img class='ct_img' src=" +
        require("../../assets/emojipng/" + list[index] + ".png") +
        ">"
      );
    },
    initVideoFun() {
      // 初始化播放器
      this.$nextTick(() => {
        if (this.anchormanRealList.status == 1) {
          // flv 加密 未加密 情况使用加密播放器
          if(this.liveList.anchormanLiveVo.isSecret == 1) {
            this.JessiPlayerInit();
          } else {
            // 有m3u8字段 并且是.m3u8后缀就用hls播放器
            if(this.liveList.anchormanLiveVo.m3u8 && this.liveList.anchormanLiveVo.m3u8.indexOf('.m3u8') != -1) {
              this.TcplayerInit();
            } else {
              switch (this.liveList.anchormanLiveVo.company) {
                // 腾讯
                case "tx":
                  this.TcplayerInit();
                  break;
                // 网宿
                case "wangsu":
                  this.WSPlayerInit();
                  break;
                // 声网
                case "shengwang":
                  this.AgoraPlayerInit();
                  break;
                default:
                  this.TcplayerInit();
                  break;
              }
            }
          }
        }
      });
    },
    TcplayerInit() {
      this.livePlayer = TCPlayer("player-container-id", {
        autoplay: true,
        muted: true,
        controls: false,
        reportable: false,
        // poster: '',
        webrtcConfig: {
          connectRetryCount: 4,
        },
      }); // player-container-id 为播放器容器 ID，必须与 html 中一致
      // replace('rtmp', 'webrtc')
      // let webrtcUrl = 'https://agmj-zyzsp.syc066.com/20220317/OWYzNDZiNz/150224/480/hls/decrypt/index.m3u8'
      let webrtcUrl = this.liveList.anchormanLiveVo.m3u8;
      // console.log("webrtc", webrtcUrl);
      this.livePlayer.src(webrtcUrl); // url 播放地址
      this.livePlayer.on("error", function (error) {
        console.info(error);
        // 做一些处理
      });
    },
    WSPlayerInit() {
      function supportWebrtc() {
        return (
          !!window.RTCPeerConnection ||
          !!(window.webkitRTCPeerConnection || window.mozRTCPeerConnection)
        );
      }
      var pcConfig = {
        iceServers: [],
        sdpSemantics: "unified-plan",
      };
      var offerSdpOption = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      };
      const onTrackCb = (param) => {
        console.log(
          "peerConnection.ontrack,kind:" +
            param.track.kind +
            ",track.id:" +
            param.track.id
        );
        var track = param.track;
        peerConnection.stream.addTrack(track);
        if (2 == peerConnection.stream.getTracks().length) {
          this.livePlayer = document.getElementById("player-container-id");
          this.livePlayer.autoplay = true;
          this.livePlayer.muted = true;
          this.livePlayer.srcObject = peerConnection.stream;
          this.livePlayer.autoplay = true;
          this.livePlayer.playsinline = true;
          var playPromise = this.livePlayer.play();
          if (playPromise) {
            playPromise
              .then(() => {
                console.log("play ok");
              })
              .catch(() => {
                console.log("play fail");
              });
          }
        }
      }


      const singleRes = (res) => {
        var data = JSON.parse(res.currentTarget.response);
        var errCode = data.code;
        var errMsg = data.message;
        if (errCode != 200) {
          console.log("singleRes fail, err = " + errMsg);
          return;
        }
        var remoteSdp = data.remoteSdp;
        peerConnection.setRemoteDescription(
          new webrtcRTCSessionDescription(remoteSdp),
          function () {
            console.log("setRemoteDescription succ");
          },
          function (e) {
            console.log("setRemoteDescription failed, err = " + e.message);
          }
        );
      }


      const sendSingle = (offer) => {
        var reqBody = {
          version: "v1.0",
          localSdp: offer,
        };
        let XHR = new XMLHttpRequest();
        XHR.onload = singleRes;
        XHR.open("POST", this.liveList.anchormanLiveVo.m3u8, true);
        XHR.send(JSON.stringify(reqBody));
      }

      var webrtcRTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;
      var webrtcRTCSessionDescription =
        window.RTCSessionDescription ||
        window.mozRTCSessionDescription ||
        window.webkitRTCSessionDescription;
      var peerConnection = new webrtcRTCPeerConnection(pcConfig);
      peerConnection.stream = new MediaStream();
      peerConnection.ontrack = onTrackCb;
      peerConnection
        .createOffer(offerSdpOption)
        .then(function (offer) {
          peerConnection.setLocalDescription(offer); // Set local sdp
          sendSingle(offer); // Send signal information, see 1.7 Signaling
        })
        .catch(function (err) {
          console.log("create offer failed : err =" + err);
        });

    },
    AgoraPlayerInit() {
      const AgoraRTC = require("agora-rtc-sdk-ng");
      let rtc = {
        // For the local audio and video tracks.
        localAudioTrack: null,
        localVideoTrack: null,
        client: null,
      };

      const options = {
        appId: this.liveList.anchormanLiveVo.shengwangAppid,
        token: this.liveList.anchormanLiveVo.shengwangToken,
        channel: this.liveList.anchormanLiveVo.shengwangChannel,
        uid: Number(this.liveList.anchormanLiveVo.shengwangUid),
        role: "audience",
      };

      let clientRoleOptions = {
        // Set latency level to low latency
        level: 1,
      };

      // 开始初始化
      rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

      rtc.client.setClientRole(options.role, clientRoleOptions);

      rtc.client.on("user-published", async (user, mediaType) => {
        // Subscribe to a remote user.
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe success");

        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack;
          remoteVideoTrack.play(
            document.getElementById("player-container-id")
          );
          this.livePlayer = document.getElementById("player-container-id");
        }

        // If the subscribed track is audio.
        if (mediaType === "audio") {
          this.AgoraAudio = user.audioTrack;
          this.$bus.emit("reset-voice", false);
        }
      });

      rtc.client.on("user-unpublished", (user) => {
        console.log('user-unpublished')
        this.AgoraRTCObj.client.leave();
        // Destroy the container.
        // this.livePlayer.remove();
      });

      rtc.client.join(
        options.appId,
        options.channel,
        options.token,
        options.uid
      );
      this.AgoraRTCObj = rtc;
    },
    async JessiPlayerInit() {
      await GetUtils.scriptInsert(`/jessibuca-pro.js?v=${+new Date()}`);
      console.log(JessibucaPro)
      let $cryptoKeyUrl = this.liveList.anchormanLiveVo.keyUrl;
      this.livePlayer = new JessibucaPro({
        container: document.getElementById("player-container-id"),
        // videoBuffer: Number(0.2), // 缓存时长
        // videoBufferDelay: Number(1), // 1000s
        isResize: false,
        // text: "",
        // debug: process.env.VUE_APP_MODE != 'production',
        // debugLevel: "debug",
        useMSE: !GetUtils.isiOS(), // 是否开启MediaSource硬解码
        // useSIMD: true, // 是否使用SIMD 软解码，仅仅wasm软解码的时候有效
        useWCS: true, // 是否开启Webcodecs硬解码
        timeout: 10000,
        heartTimeoutReplayUseLastFrameShow: true,
        audioEngine: "script",
        qualityConfig: ['4K', '8K'],
        forceNoOffscreen: false,
        isNotMute: false,
        heartTimeout: 10,
        useCanvasRender: true,
        isM7sCrypto: true,
        cryptoKeyUrl: $cryptoKeyUrl,
        isFullResize: true
      },);
      var playhref = this.liveList.anchormanLiveVo.m3u8;
      // var playhref = "https://onehwzbpull.lyksly.cn/live/test.flv"
      if (playhref) {
        if($cryptoKeyUrl != '' && this.liveList.anchormanLiveVo.isSecret == 1){
          this.livePlayer.setCryptoKeyUrl($cryptoKeyUrl);
        }
        this.livePlayer.play(playhref);
      }
      console.log(this.livePlayer.getDecodeType())
    },
    // 停播后销毁播放器
    destoryLivePlayer() {
      if(this.liveList.anchormanLiveVo.m3u8 && this.liveList.anchormanLiveVo.m3u8.indexOf('.m3u8') != -1) {
        this.livePlayer.dispose();
      } else {
        switch (this.liveList.anchormanLiveVo.company) {
          // 腾讯
          case "tx":
            this.livePlayer.dispose();
            break;
          // 网宿
          case "wangsu":
            this.WSPlayerInit();
            break;
          // 声网
          case "shengwang":
            this.AgoraRTCObj.client.leave();
            break;
          default:
            this.livePlayer.dispose();
            break;
        }
      }
    },
    damuToList(e) {
      if (!this.isEffects) {
        // 特效已关闭
        return false;
      }
      let that = this;
      this.damuId++;
      let fontClass = this.randomSpeed(this.damuId);
      // console.log("发送弹幕01", e, this.isEffects, this.isLivePage);
      let danmakuClass = `${this.colorArr[Math.floor(Math.random() * 16) + 1]}`;
      let msgDom = e.ct.replace(/(\[ht[a-z]\d{2}\])/g, this.emotionImgSrc);
      setTimeout(() => {
        let list = {
          color: danmakuClass,
          fontClass: fontClass.fontSize,
          text: msgDom,
        };
        that.danmus.push(list);
      }, 500);
    },
    // 切换流
    changePlayerFlow(item) {
      this.livePlayer.destroy().then(()=>{
        this.liveList.anchormanLiveVo.isSecret = item.isEncryption;
        this.liveList.anchormanLiveVo.m3u8 = item.pullUrl;
        this.JessiPlayerInit();
      })
    }
  },
};
</script>

<style lang="scss">
.video-js .vjs-tech {
  object-fit: cover;
}

.vue-danmaku {
  width: 100%;
  height: 100%;

  .danmakuMsg {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: bolder;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
    img {
      display: block;
      height: 30px;
      margin: 0 5px;
    }

    &.color_1 {
      color: #80f5e0 !important;
      font-size: 30px;
    }
    &.color_2 {
      color: #83e2f9 !important;
      font-size: 25px;
    }
    &.color_3 {
      color: #ff83f3 !important;
      font-size: 28px;
    }
    &.color_4 {
      color: #ff9589 !important;
      font-size: 30px;
    }
    &.color_5 {
      color: #ffdd74 !important;
      font-size: 25px;
    }
    &.color_6 {
      color: #d4fc79 !important;
      font-size: 28px;
    }
    &.color_7 {
      color: #61f576 !important;
      font-size: 30px;
    }
    &.color_8 {
      color: #98ffbe !important;
      font-size: 25px;
    }
    &.color_9 {
      color: #009efd !important;
      font-size: 28px;
    }
    &.color_10 {
      color: #6f3cff !important;
      font-size: 30px;
    }
    &.color_11 {
      color: #1ece85 !important;
      font-size: 25px;
    }
    &.color_12 {
      color: #e2d1c3 !important;
      font-size: 28px;
    }
    &.color_13 {
      color: #dad4ec !important;
      font-size: 30px;
    }
    &.color_14 {
      color: #fee140 !important;
      font-size: 25px;
    }
    &.color_15 {
      color: #80d0c7 !important;
      font-size: 28px;
    }
    &.color_16 {
      color: #e7627d !important;
      font-size: 30px;
    }

    &.size24 {
      font-size: 24px;
    }
    &.size30 {
      font-size: 28px;
    }
  }
}

::v-deep .vjs-errors-dialog .vjs-control.vjs-close-button {
  z-index: 999;
}
</style>
<style lang="scss" scoped>
.live-video {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 3;

  .live-video-play {
    width: 100%;
    // height: 56.27vw;
    height: 100vh;
    background: transparent;
  }

  .video_wrapper {
    width: 100vw;
    height: 100vh;
    position: static;

    .vplayerCtn {
      width: 100vw;
      height: 100%;
      background: transparent;
      object-fit: cover;
    }

    .danmu {
      width: 100vw;
      height: 30vh;
      position: absolute;
      top: 120px;
      left: 0;
      z-index: 10;
    }

    .video_id {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
    }

    .video_pre_loading {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      // background: url("@/assets/images/bj_girl.png") no-repeat top left;
      // background-size: 100% 100%;
      z-index: 2;
      // filter: blur(5px);
      // -webkit-filter: blur(5px);
    }

    .video_bj {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(10px);
    }

    .loading_Img {
      position: absolute;
      bottom: 80px;
      z-index: 9;
      width: 100%;
      display: flex;
      justify-content: center;

      .img1 {
        background-color: #dfb996;
        height: 1.2px;
        animation: loading 1.2s infinite;
        animation-timing-function: ease;
        animation-direction: normal;
        -webkit-animation: loading 1.2s infinite;
        -webkit-animation-timing-function: ease;
        -webkit-animation-direction: normal;
      }
    }

    @keyframes loading {
      0% {
        width: 40%;
        opacity: 1;
      }

      100% {
        width: 100%;
        opacity: 0;
      }
    }

    @-webkit-keyframes loading {
      0% {
        width: 40%;
        opacity: 1;
      }

      90% {
        width: 100%;
        opacity: 0;
      }

      100% {
        width: 40%;
        opacity: 0;
      }
    }
  }

  .live_window {
    width: 100%;
    height: 100%;

    .video_bj {
      display: block;
      width: 100%;
      height: 100%;
      filter: blur(10px);
      -webkit-filter: blur(10px);
    }
  }

  ::v-deep .xgplayer-controls {
    background-image: none !important;
    bottom: 20px;
  }

  ::v-deep .xgplayer-live {
    display: none !important;
  }

  ::v-deep .xgplayer-fullscreen {
    display: none !important;
  }

  ::v-deep .xgplayer-play {
    margin-left: 15px;
  }

  ::v-deep .xgplayer-volume {
    margin-right: 10px !important;
  }

  ::v-deep .xgplayer-skin-default {
    background: transparent;

    video {
      position: absolute;
      top: -5px;
    }

    .xgplayer-poster {
      display: none;
    }

    .xgplayer-enter {
      background: transparent;
      display: none;
    }

    .xgplayer-start {
      display: none !important;
      background: none !important;
    }

    .xgplayer-danmu {
      position: absolute;
      top: 106px;
      height: 55vh;
    }
  }

  ::v-deep .damu_info_text {
    font-size: 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);

    .ct_img {
      width: 20px;
      height: 20px;
    }
  }
}

// 苹果8手机
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  ::v-deep .xgplayer-skin-default .xgplayer-danmu {
    height: 49vh !important;
  }
}
</style>
